import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import FocusTrap from 'focus-trap-react';
import MenuButton from './MobileMenuButton';
import LinkList from './LinkList';

const Container = styled.div((props: { open: boolean }) => ({
  background: '#fcf9f4',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowX: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  transition: 'width 0.3s ease',
  width: props.open ? '100vw' : 0,
  zIndex: 900,
}));

const Ul = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: 120px;

  li {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 12px;

    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
      padding: 8px;
      background-image: linear-gradient(#bf841e, #bf841e);
      background-size: 0% 0.1em;
      background-position-y: 100%;
      background-position-x: 0%;
      background-repeat: no-repeat;
      transition: background-size 0.2s ease-in-out;
      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus {
          background-size: 100% 0.1em;
        }
      }
    }
  }
`;

const Menu = () => {
  const [open, setOpen] = useState(false);
  const [isMenuBlocked, setIsMenuBlocked] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleMenuButtonClick = () => {
    // Lock scrolling
    if (!isMenuBlocked) {
      if (!open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
      setOpen(!open);
      setIsMenuBlocked(true);
      timerRef.current = setTimeout(() => setIsMenuBlocked(false), 300);
    }
  };

  const handleLinkClick = () => {
    document.body.style.overflow = '';
    setOpen(false);
    setIsMenuBlocked(false);
  };

  return (
    <FocusTrap active={open}>
      <div>
        <MenuButton onClick={handleMenuButtonClick} open={open} />
        <Container open={open} role="dialog">
          {open && (
            <Ul onClick={handleLinkClick}>
              <LinkList />
            </Ul>
          )}
        </Container>
      </div>
    </FocusTrap>
  );
};

export const MemoizedMenu = React.memo(Menu);

export default MemoizedMenu;
