import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { DesktopMenu, MobileMenu } from './navmenu';

const Root = styled.header`
  background-color: transparent;
  color: white;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 100%;
  z-index: 999;
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  width: 1100px;
  padding: 0 24px;

  & > a {
    user-select: none;
    text-decoration: none;
    color: white;
  }

  @media (max-width: 1200px) {
    max-width: 860px;
  }

  @media (max-width: 900px) {
    max-width: 600px;
  }

  @media (max-width: 660px) {
    max-width: 480px;
  }
`;

const Header = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  // Listeners
  useEffect(() => {
    const onResize = () => setWidth(window.innerWidth);

    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize, { passive: true });

    // clean up code
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Root>
      <Nav>
        <Link aria-label="home page" to="/">
          Julia Shchukina
        </Link>
        {width > 660 ? <DesktopMenu /> : <MobileMenu />}
      </Nav>
    </Root>
  );
};

export const MemoizedHeader = React.memo(Header);
export default MemoizedHeader;
