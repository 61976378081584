import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { use100vh } from 'react-div-100vh';
import Footer from './Footer';
import Header from './Header';

type Props = {
  children: React.ReactNode;
};

const Root = styled.div((props: { height: number | undefined }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: props.height,
}));

const ContentBody = styled.div`
  flex: 1;
`;

const Layout: React.FC<Props> = ({ children }: Props) => {
  const height = use100vh();
  const [viewPortHeight, setViewPortHeight] = useState<number>();

  useEffect(() => {
    if (height) {
      setViewPortHeight(height);
    }
  }, [height]);

  if (!viewPortHeight) {
    return null;
  }

  return (
    <Root height={viewPortHeight}>
      <Header />
      <ContentBody>{children}</ContentBody>
      <Footer />
    </Root>
  );
};

export default Layout;
