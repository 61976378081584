import React, { useState } from 'react';
import styled from '@emotion/styled';

const Container = styled.button((props: { open: boolean }) => ({
  height: '40px',
  width: '40px',
  alignItems: 'center',
  background: 'none',
  border: 0,
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  transition: 'background-color 0.2s',
  zIndex: 910,
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .15)',
      cursor: 'pointer',
    },
  },
  div: {
    height: '2px',
    width: '20px',
    background: props.open ? 'black' : 'white',
    transition: 'all 0.2s ease',
    ':not(&:last-child)': {
      marginBottom: '5px',
    },
  },
}));

const LineTop = styled.div((props: { open: boolean }) => ({
  transform: props.open ? 'translateX(3px) rotate(45deg)' : 'none',
  transformOrigin: 'top left',
}));

const LineMiddle = styled.div((props: { open: boolean }) => ({
  opacity: props.open ? 0 : 1,
  transform: props.open ? 'translateX(-16px)' : 'none',
}));

const LineBottom = styled.div((props: { open: boolean }) => ({
  transform: props.open ? 'translateX(2px) rotate(-45deg)' : 'none',
  transformOrigin: 'top left',
}));

interface IMenuButtonProps {
  onClick: () => void;
  open: boolean;
}

const MenuButton: React.FC<IMenuButtonProps> = (props: IMenuButtonProps) => {
  const { onClick, open } = props;

  return (
    <Container
      aria-label="Navigation menu"
      aria-expanded={false}
      onClick={onClick}
      onKeyDown={onClick}
      open={open}
      tabIndex={0}
    >
      <LineTop open={open} />
      <LineMiddle open={open} />
      <LineBottom open={open} />
    </Container>
  );
};

export default MenuButton;
