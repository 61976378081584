import React from 'react';
import styled from '@emotion/styled';
import LinkList from './LinkList';

const UL = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    font-size: 1rem;
    padding: 0px 20px;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      transition: color 0.2s;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: rgba(188, 79, 255, 1);
        }
      }
    }
  }
`;

const DesktopMenu = () => {
  return (
    <UL role="menubar">
      <LinkList />
    </UL>
  );
};

export const MemoizedDesktopMenu = React.memo(DesktopMenu);

export default MemoizedDesktopMenu;
